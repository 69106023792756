<template>
  <section class="homepage-section">
    <div class="top">
      <picture v-if="desktop">
        <source srcset="app/assets/images/home/intro_left.webp" type="image/webp">
        <img src="app/assets/images/home/intro_left.png">
      </picture>

      <div class="content">
        <TwinColorTitle class="title">
          Customized group travel made simple with<br><em>TourHero</em>
        </TwinColorTitle>

        <NavLink href="/plan-a-trip">
          <Button id="home-plan" color="red" text="Plan a trip"/>
        </NavLink>
      </div>

      <picture v-if="desktop">
        <source srcset="app/assets/images/home/intro_right.webp" type="image/webp">
        <img src="app/assets/images/home/intro_right.png">
      </picture>

      <picture v-if="mobile">
        <source srcset="app/assets/images/home/intro_center.webp" type="image/webp">
        <img src="app/assets/images/home/intro_center.png">
      </picture>
    </div>

    <div class="bottom">
      <div class="text">Our tour partners were featured on</div>
      <NewsTicker class="partners" :speed="25" :enabled="mobile">
        <img src="app/assets/images/home/partners/conde_nast_traveler.png">
        <img src="app/assets/images/home/partners/national_geographic.png">
        <img src="app/assets/images/home/partners/lonely_planet.png">
        <img src="app/assets/images/home/partners/vice.png">
        <img src="app/assets/images/home/partners/new_york_times.png">
        <img src="app/assets/images/home/partners/forbes.png">
      </NewsTicker>
    </div>
  </section>
</template>

<script setup>
import TwinColorTitle from "@/components/Theme2/TwinColorTitle.vue";
import Button from "@/components/Theme2/Button.vue";
import NavLink from "@/components/NavLink.vue";
import { useShared } from "@/helpers/vueHelpers.js";
import NewsTicker from "@/modules/MbaExclusive/Components/NewsTicker.vue";

const { mobile, desktop } = useShared();
</script>

<style lang="scss" scoped>
section.homepage-section {
  @include main-unwrap;

  padding-top: 64px;
  padding-bottom: 64px;
  margin-bottom: 64px;
  background: linear-gradient(255deg, rgba(234, 241, 243, 0.20) 10.16%, rgba(227, 242, 253, 0.80) 87.06%);

  @include mobile {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .top {
    display: flex;
    margin-bottom: 16px;
    justify-content: center;
    overflow: hidden;

    @include mobile {
      flex-direction: column;
      margin-bottom: 36px;
    }

    picture:first-child {
      display: flex;
      justify-content: flex-start;
    }

    picture:last-child {
      display: flex;
      justify-content: flex-end;
    }

    img {
      width: calc(100% - 20px);
      height: auto;
      max-width: 100%;
      flex-shrink: 1;

      @include mobile {
        width: 100%;
        height: auto;
      }
    }
  }

  .content {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 0 32px;
    margin: auto;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  .title {
    max-width: 600px;

    @include desktop {
      font-size: 48px;
      line-height: 52px;
    }
  }

  .bottom {
    color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .partners {
    display: flex;
    height: 40px;
    justify-content: center;

    img {
      height: 100%;
    }
  }
}
</style>
